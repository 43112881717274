import { template as template_c4cf54db739f4f558f63cd76b11d98df } from "@ember/template-compiler";
const SidebarSectionMessage = template_c4cf54db739f4f558f63cd76b11d98df(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
